import State from "@/store/organization/organizations"
import Organization, {OrganizationPermission, OrganizationType} from "@/managers/data/organization/organization"
import Portal from "@/managers/portal"
import ListMain from "@/managers/base/listMain"
import {watch, when} from "@/store/store"
import Notifications from "@/managers/session/notifications"
import DealConfiguration from "@/managers/data/organization/dealConfiguration"
import {firstOrNull} from "@/utility/structure/array"
import {addGlobalEventListener, globalEvents, sendGlobalEvent} from "@/managers/session/globalEvents"

class Organizations extends ListMain<Organization> {
	public get wasMainSetFromLocation(): boolean {
		return this.state.wasMainSetFromLocation
	}

	public get main(): Organization | null {
		return super.main
	}

	public set main(value: Organization | null) {
		super.main = value
		this.state.setWasMainSetByLocation(false)
	}

	public get canManageMain(): boolean {
		return this.main?.canManage ?? false
	}

	public get hasAnyNoneSingleStore(): boolean {
		return this.hasAny && this.list!.some(o => !o.isSingleStore)
	}

	protected get state(): typeof State {
		return State
	}

	constructor() {
		super("organizations")

		watch(() => this.state.mainId, mainId => {
			if (mainId === null)
				return

			this.updatePermission(mainId)
				.catch(reason => Notifications.warning("Failed to update permission for main org: ", reason))
		})

		addGlobalEventListener(globalEvents.organizationAdded, (organization: Organization) => {
			if (this.getById(organization.id) === null)
				this.updateList() // todo: Optimize this by cloning the organization
		})
		addGlobalEventListener(globalEvents.organizationChanged, () => this.updateList()) // todo: Optimize this by using the event parameter
	}

	public async setMainFromLocation(organizationId: number): Promise<void> {
		await when(() => this.isReady)
		const organization =  this.getById(organizationId)

		if (organization === null)
			throw new Error(`Organization with id: ${organizationId} not found`)

		this.state.setWasMainSetByLocation(true)
		super.main = organization
	}

	public createSingleStore(name: string, key: string, language: string, timeZone: string, phone: string, industry: number, currency?: string): Promise<Organization> {
		return Portal.call.organizations.post(
			true,
			true,
			name,
			key,
			OrganizationType.singleStore,
			phone,
			industry,
			false,
			false,
			currency,
			undefined,
			[
				{
					Name: name,
					Key: key,
					Language: language,
					TimeZone: timeZone,
					ShareInsightsWithOrganization: true,
					UserDefinedId: null
				}
			]
		).response
			.then(Organization.fromApi)
			.then(organization => {
				this.state.add(organization)

				sendGlobalEvent(globalEvents.organizationAdded, organization)

				return organization
			})
	}

	public async modifyLogo(id: number, file: File): Promise<string> {
		const fileInfo = await Portal.call.organizations.logosPost(id, file).response
		this.modifyItem({id, logoUrl: fileInfo.Url})
		return fileInfo.Url
	}

	public modifyIsCurrency(id: number, currency: string): Promise<void> {
		return this.modify({id, currency})
	}

	public modifyIsReviewRequired(id: number, isRequired: boolean): Promise<void> {
		return this.modify({id, isReviewRequired: isRequired})
	}

	public modifyDealTexts(id: number, dealConfiguration: DealConfiguration): Promise<void> {
		return this.modifyPatch({id, dealConfiguration})
	}

	public modifyDealEnabled(id: number, isDealsEnabled: boolean, isLiveDealsEnabled: boolean, isAlcoholEnabled: boolean): Promise<void> {
		return this.modifyPatch({id, isDealsEnabled, isLiveDealsEnabled, isAlcoholEnabled})
	}

	public async modifyIsDealPrioritizingMessaging(id: number, isPrioritizingMessaging: boolean): Promise<void> {
		return this.modifyPatch({id, isPrioritizingMessaging})
	}

	public async modifyIsAlcoholEnabled(id: number, isAlcoholEnabled: boolean): Promise<void> {
		return this.modifyPatch({id, isAlcoholEnabled})
	}

	public async modifyIsCustomerAnonymized(id: number, isCustomersAnonymized: boolean): Promise<void> {
		return this.modifyPatch({id, isCustomersAnonymized})
	}

	public async refreshLocationSignupToken(): Promise<void> {
		const id = this.main!.id

		await Portal.call.organizations.patch(id, "refresh").response
		const list = await this.apiLoad(true)
		const main = firstOrNull(list, o => o.id === id)

		if (main !== null)
			this.modifyItem({id, locationSignupToken: main.locationSignupToken})
	}

	public async refreshContentSubscriptionToken(): Promise<void> {
		const id = this.main!.id

		await Portal.call.organizations.patch(id, undefined, "refresh").response
		const list = await this.apiLoad(true)
		const main = firstOrNull(list, o => o.id === id)

		if (main !== null)
			this.modifyItem({id, contentSubscriptionToken: main.contentSubscriptionToken})
	}

	public async updatePermission(id: number, force = false): Promise<OrganizationPermission> {
		if (!force && this.isReady) {
			const index = this.getIndex(id)

			if (index !== null && this.list![index].hasUpdatedPermission)
				return this.list![index].permission!
		}

		return Portal.call.organizations.permissionsGet(id)
			.response
			.then(OrganizationPermission.fromApi)
			.then(permission => {
				const index = this.getIndex(id)

				if (index !== null)
					this.modifyItem({id, permission})

				return permission
			})
	}

	protected apiDelete(dependent: boolean, id: Organization["id"]): Promise<void> {
		return Promise.reject(new Error("Not implemented"))
	}

	protected apiLoad(dependent: true): Promise<Organization[]> {
		return Portal.call.organizations.get()
			.response.then(organizations => organizations.map(Organization.fromApi))
			.then(organizations => organizations.sort((o1, o2) => o1.name.localeCompare(o2.name)))
	}

	private async modify(data: Partial<Organization> & Pick<Organization, "id">): Promise<void> {
		const organization = {...this.getBytIdOrFail(data.id), ... data}

		await Portal.call.organizations.put(
			organization.id, organization.isReviewRequired, organization.isNotificationsEnabled, organization.isActive, organization.currency
		).response
		this.modifyItem(data)
	}

	private async modifyPatch(data: Partial<Organization> & Pick<Organization, "id">): Promise<void> {
		const organization = {...this.getBytIdOrFail(data.id), ... data}

		const dealConfig = organization.dealConfiguration.toOrganizationApi(
			organization.isDealsEnabled, organization.isLiveDealsEnabled, organization.isCustomersAnonymized, organization.isPrioritizingMessaging, organization.isAlcoholEnabled
		)

		await Portal.call.organizations.patch(organization.id, undefined, undefined, dealConfig).response
		this.modifyItem(data)
	}

	private getBytIdOrFail(id: number): Organization {
		const organization = this.getById(id)

		if (organization === null)
			throw new Error(`Failed to find organization with id: ${id}`)

		return organization
	}
}

export {DealConfiguration, Organization, OrganizationPermission, OrganizationType}
export default new Organizations()
