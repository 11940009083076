import Vue from "vue"
import Log from "@/managers/session/logging/log"
import {localStorage} from "@/managers/system/fallbackStorage"
import Notifications from "@/managers/session/notifications"
import {getCookie} from "@/managers/system/cookies"

class Tracking {
	private static readonly CAN_TRACK_KEY = "canTrackUnauthenticated"
	private static readonly CROSS_CONSENT_KEY = "poCrossConsent"
	private static readonly COOKIEBOT_KEY = "CookieConsent"
	private state = Vue.observable({canTrackUnauthenticated: null as boolean | null})

	public get canTrackUnauthenticated(): boolean {
		return this.state.canTrackUnauthenticated === true
	}

	public get hasUpdatedCanTrack(): boolean {
		return this.state.canTrackUnauthenticated !== null
	}

	constructor() {
		this.state.canTrackUnauthenticated = this.getCanTrackFromStorage()
			?? this.getCrossConsent(CookieBotConsentCategory.statistics)
			?? this.getCookieBotConsent(CookieBotConsentCategory.statistics)
	}

	public updateCanTrackUnauthenticated(canTrack: boolean): void {
		this.state.canTrackUnauthenticated = canTrack
		localStorage.setItem(Tracking.CAN_TRACK_KEY, JSON.stringify(canTrack))
	}

	public login(method: string): void {
		Log.login(method)
	}

	public signUp(method: string): void {
		Log.signUp(method)
	}

	public event(category: string, action: string, label?: string, nonInteraction?: boolean): void {
		Log.event(
			category,
			action,
			label
		)
	}

	private getCanTrackFromStorage(): boolean | null {
		const trackValue = localStorage.getItem(Tracking.CAN_TRACK_KEY)

		if (trackValue === null)
			return null

		try {
			return JSON.parse(trackValue) === true
		} catch (error: any) {
			Notifications.debug("Failed to parse canTrackUnauthenticated: " + error)
			return null
		}
	}

	private getCrossConsent(category: CookieBotConsentCategory): boolean | null {
		let cookieValue = getCookie(Tracking.CROSS_CONSENT_KEY)

		if (cookieValue === null || cookieValue === "-1")
			return null

		try {
			cookieValue = decodeURIComponent(cookieValue)
			const parsedCookieConsent = JSON.parse(cookieValue)
			return parsedCookieConsent && !!parsedCookieConsent[category]
		} catch (error: any) {
			return null
		}
	}

	private getCookieBotConsent(category: CookieBotConsentCategory): boolean | null {
		let cookieValue = getCookie(Tracking.COOKIEBOT_KEY)

		if (cookieValue === null || cookieValue === "-1")
			return null

		try {
			cookieValue = decodeURIComponent(cookieValue)

			const parsedCookieConsent = JSON.parse(
				cookieValue.replace(/%2c/g, ",")
					.replace(/'/g, "\"")
					.replace(/([{\[,])\s*([a-zA-Z0-9_]+?):/g, "$1\"$2\":")
			)
			return parsedCookieConsent && !!parsedCookieConsent[category]
		} catch (error: any) {
			return null
		}
	}
}

enum CookieBotConsentCategory {
	statistics = "statistics",
	marketing = "marketing",
	necessary = "necessary",
	preferences = "preferences"
}

export default new Tracking()
